body {
  font-size: calc(0.7em + 0.25vw);
  font-weight: 300;
  line-height: 1.75;
  min-width: 300px;
  margin: 0;
  font-family: "effra", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
