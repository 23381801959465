section {
  display: grid;
}

header {
  text-align: center;
  grid-template-rows: 1fr 1fr 1fr;
}

header,
section {
  display: grid;
  margin: 0 auto;
  height: 100vh;
  align-items: center;
}

header,
nav,
section {
  padding: 2rem;
}

section {
  max-width: 60rem;
}

#about,
#work {
  animation: fadeIn 2s ease;
}

#clients {
  justify-content: center;
}

.siteHero h1 {
  opacity: 0;
}

.siteHero h2 {
  opacity: 0;
  margin-bottom: 0;
}

.enter-done .siteHero h1 {
  animation: fadeIn 1s ease;
  animation-fill-mode: forwards;
}

.enter-done .siteHero h2{
  animation: fadeIn 4s ease;
  animation-fill-mode: forwards;
}

// .menuOpen .siteHero {
//   animation: fadeOut 1.5s forwards;
// }



.siteLogo {
  grid-row-start: 2;
  margin: 0 auto;
  height: 20vh;
  max-width: 33.33vw;
  transition: 1s;
  @media screen and (min-width: 800px){
    max-width: 16.66vw;
  }
}

.siteHero {
  grid-row-start: 3;
}

.video-wrapper {
  background: black;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  margin: auto;
}
		
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//Clients
.clientGrid {
  margin: 2rem auto;
  display: grid;
  list-style: none;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1frFfade; 
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  padding: 0;
  @media screen and (min-width: 800px){
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr; 
  }
}

.clientGrid li {
  width: 20vw;
  @media screen and(min-width: 800px){
    width: 15vw;
  }
}

.clientGrid li svg {
  opacity: 0;
  width: 20vw;
  display: block;
  margin: 0 auto;
  animation: fadeIn 1s forwards;
  @media screen and(min-width: 800px){
    width: 12.5vw;
  }
}

@for $i from 1 to 13 {
  .clientGrid li:nth-child(#{$i}) svg { animation-delay: $i * 0.15s; }
}


// About

.column-wrapper {
  display: flex;
  flex-direction: column;
  
  @media screen and(min-width: 840px){
  flex-direction: row;
    aside {
      width: 50%;
    }
  }
}

.profileAside-image {
  text-align: center;
}

.profileAside-image figure {
  position: relative;
  height: 125px;
}

.profileImage {
  position: absolute;
  border-radius: 100%;
  width: 125px;
  height: 125px;
  left: 50%;
  background: #ccc;
  box-shadow: 1px 1px 3px grey;
  transition: 1s;
  transform:translate(-50%, 0%);
  animation: profileImageAnimation 1s forwards;
}

  @media screen and(min-width: 800px){
    .profileAside-image figure { height: 150px }
    .profileImage { width: 150px; height: 150px;}
  }

.profileImage.back {
  background: black;
}

.in-view .profileImage {
  transform: translate(-5%, 0%);
}

.in-view .profileImage.back {
    transform: translate(-95%, 0%);
}

//Footer

footer {
  align-items: flex-end;
  display: flex;
  height: 100vh;
  position: relative;
  z-index: 3;
}

.social {
  display: flex;
  justify-content: space-between;
  border-top: .2rem solid;
  margin: 0 auto;
  transition: 1s;
  margin-bottom: 15vh;
  transform: translateY(calc(100% + 15vh));
}

.enter-done .social {
  transform: translateY(0%);
}

.social svg {
  width: 3rem;
  @media screen and(min-width: 800px){
    width: 4rem;
  }
}

.visuallyHidden {
  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px; width: 1px; 
  margin: -1px; padding: 0; border: 0;
}