//Hamburger menu

.menuToggle {
  display: none;
  position: fixed;
  z-index: 5;
  top: 2rem;
  left: 2rem;
  outline: 0;
  background: transparent;
  border: 0;
  width: 32px;
  height: 32px;
  opacity: 1;
}

.menuToggle {
  animation: dropDown 1s forwards;
}

.menuToggle span {
  display: block;
  position: absolute;
  height: .2rem;
  width: 100%;
  background: black;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transform-origin: left center;
  transition: .5s ease-in-out;
}

.menuToggle span:nth-child(1) {
  top: 8px;
}

.menuToggle span:nth-child(2) {
  top: 24px;
}

.menuToggle{
  transform: translateY(-200%);
  animation-fill-mode: forwards;
} 

.menuOpen .menuToggle span:nth-child(1) {
  transform: rotate(45deg);
  top: 0px;
  left: 4px;
}

.menuOpen .menuToggle span:nth-child(2) {
  transform: rotate(-45deg);
  top: 23px;
  left: 4px;
}