//Shapes
#home,
#contact {
  position: relative;
  max-width: none;
  overflow: hidden;
}

.animated-wrapper,
.symbol-wrapper {
  position: absolute;
  z-index: 2;
  top: 0;
}

.shape,
.symbol {
  overflow: visible;
  display: block;
  height: 100vh;
  width: 100vw;
}

.shape {
  animation: scrollSpin 4s forwards ease-out; 
  animation-delay: .75s;
  transform: translateY(100vh); 
}

.symbol {
  animation: scroll 2.5s linear; 
  animation-delay: .75s;
  transform: translateY(100vh); 
}

.appear .siteLogo,
.enter-done .siteLogo {
  position: fixed;
  opacity: .05;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  pointer-events: none;
}

.enter-done .siteLogo {
 opacity: 1;
}

.shape-wrapper .shape:nth-child(odd),
.symbol-wrapper .symbol:nth-child(odd){
  margin-left: -25vw;
}

.shape-wrapper .shape:nth-child(even),
.symbol-wrapper .symbol:nth-child(even){
  margin-right: -25vw;
  float: right;
}
