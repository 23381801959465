h1 {
  font-weight: 500;
}

h2,h3,h4,h5,h6 {
  font-weight: 100;
}

h1,h2,h3,h4,h5,h6 {
  line-height: 1.4;
}

a,
p {
  font-size: 1.25em;
}

p {
  max-width: 42.5rem;
}

a {
  display: block;
  color: black;
  font-weight: 500;
  text-decoration: none;
}

a[download] {
  font-weight: 100;
  margin-bottom: 1rem;
}

section h1 {
  font-size: 1.75em;
  font-weight: 600;
}