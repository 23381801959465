@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeUp {
  0% {
    transform: translateY(80px);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes scroll {
  0% {transform: translateY(100%)}
  100% {transform: translateY(-300%)}
}

@keyframes scrollSpin {
  0% {transform: translateY(100%) rotate(0deg)}
  100% {transform: translateY(-300%) rotate(20deg)}
}

@keyframes dropDown {
  0%{transform: translateY(-200%)}
  100%{transform: translateY(0%)}
}