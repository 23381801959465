
nav {
  position: fixed;
  z-index: 4;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

nav a {
  font-size: 1.25em;
  font-weight: 500;
  margin-right: 1.5rem;
  color: black;
  text-decoration: none;
}

@media screen and(max-width: 840px),
screen and(max-height: 660px){

  .menuToggle {
    display: block;
  }
  .menuClosed nav {
    opacity: 0;
    pointer-events: none;
  }

  nav {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.95);
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  nav a { font-size: 2em; margin: 1rem}

  nav.enter-active {animation: fadeIn 1.5s forwards;}
  nav.exit-active {animation: fadeOut 1.5s forwards;}

  @media screen and(min-width: 400px) and (orientation: landscape){
    nav {
      flex-direction: row;
    }
  }
}

//Back home
.homeButton {
  position: absolute;
  z-index: 5;
  right: 2rem;
  top: 2rem;
}

.homeButton svg {
  width: 2.5rem;
}

